html,body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}
#root {
  width: 1600px;
  height: 900px;
  transform-origin: left top;
}
body {
  background: linear-gradient(180deg, #081F6D 0%, #0B2B98 50%, #081F6D 100%);
  font-family: Kanit;
  color: #fff;
  line-height: 1.25;
}
body > div {
  background: url("../public/assets/bg.png");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
.page-type {
  line-height: 1;
  font-weight: 600;
  font-size: 2.5rem;
  padding: 0.25rem 1rem;
  border: 0.25rem solid transparent;
  border-radius: 99rem;
  background:
    linear-gradient(180deg, #D60906 0%, #450001 50%) padding-box,
    linear-gradient(90deg, #AA7534 0%, #FFCF83 30.5%, #F9E29C 55%, #EBE867 75%, #98581B 100%) border-box;
}
.round-status {
  background: #000000B2;
  border-radius: 99rem;
  font-weight: 500;
  font-size: 1.75rem;
  width: fit-content;
  padding: 0.5rem;
  line-height: 1;
}
.event-date {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  border: 0.25rem solid transparent;
  padding: 0.25rem 1.5rem;
  background:
    linear-gradient(#000000, #000000) padding-box,
    linear-gradient(90deg, #AA7534 0%, #FFCF83 30.5%, #F9E29C 55%, #EBE867 75%, #98581B 100%) border-box;
  border-radius: 999rem;
  text-wrap: nowrap;
  text-transform: uppercase;
}
.rank-thead {
  font-weight: 700;
  line-height: 1;
  position: relative;
  background: linear-gradient(90deg, rgba(71, 41, 23, 0) 0%, rgba(152, 88, 27, 0.7) 15%, rgba(254, 210, 83, 0.5) 40%, rgba(235, 232, 103, 0.7) 60%, rgba(152, 88, 27, 0.5) 85%, rgba(71, 41, 23, 0) 100%);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 4px 12px #000000B2;
}
.rank-thead::before, .rank-thead::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background: linear-gradient(90deg, rgba(170, 117, 52, 0) 0%, #FFCF83 30.5%, #F9E29C 55%, #EBE867 75%, rgba(152, 88, 27, 0) 100%);
}
.rank-thead::before {
  top: 0;
}
.rank-thead::after {
  bottom: 0;
}
.rank-table .row > div {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.25rem;
}
.rank-tbody {
  padding: 0.5rem 0rem;
  font-weight: 500;
}
.rank-tbody > div:nth-child(even) {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
}
.rank-tbody > div:nth-child(odd) {
  background: linear-gradient(90deg, rgba(164, 108, 55, 0) 0%, rgba(164, 108, 55, 0.5) 50%, rgba(164, 108, 55, 0) 100%);
}
.rank-tbody > div:first-child {
  font-size: 1.75rem;
  font-weight: 700;
  color: #C4C4C4;
}
.rank-list:nth-child(2) {
  color: #FFCF83;
}
.rank-list:nth-child(3) {
  color: #C6C6C6;
}
.rank-list:nth-child(4) {
  color: #F7B3B4;
}
.border-between > div:not(:last-child) {
  position: relative;
}
.border-between > div:not(:last-child)::before {
  content: "";
  position: absolute;
  width: 0.25rem;
  right: -0.125rem;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(170, 117, 52, 0) 0%, #FFCF83 30.5%, #F9E29C 55%, #EBE867 75%, rgba(152, 88, 27, 0) 100%);
}
.text-gold {
  background: linear-gradient(90deg, #AA7534 0%, #FFCF83 30.5%, #F9E29C 55%, #EBE867 75%, #AA7534 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.animate-blink {
  animation: blinker 1s ease-in-out infinite;
}

@keyframes blinker {
  25% {
    opacity: 0.5;
  }
  75% {
    opacity: 1;
  };
  100% {
    opacity: 0.2;
  };
}
@keyframes sf-fly-by-1 {
  from {
      transform: translateZ(-600px);
      opacity: 0.5;
  }
  to {
      transform: translateZ(0);
      opacity: 0.5;
  }
}
@keyframes sf-fly-by-2 {
  from {
      transform: translateZ(-1200px);
      opacity: 0.5;
  }
  to {
      transform: translateZ(-600px);
      opacity: 0.5;
  }
}
@keyframes sf-fly-by-3 {
  from {
      transform: translateZ(-1800px);
      opacity: 0.5;
  }
  to {
      transform: translateZ(-1200px);
      opacity: 0.5;
  }
}
.star-field {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  perspective: 600px;
  -webkit-perspective: 600px;
  z-index: -1;
}
.star-field .layer {
  box-shadow: -411px -476px #cccccc, 777px -407px #d4d4d4, -387px -477px #fcfcfc, -91px -235px #d4d4d4, 491px -460px #f7f7f7, 892px -128px #f7f7f7, 758px -277px #ededed, 596px 378px #cccccc, 647px 423px whitesmoke, 183px 389px #c7c7c7,
      524px -237px #f0f0f0, 679px -535px #e3e3e3, 158px 399px #ededed, 157px 249px #ededed, 81px -450px #ebebeb, 719px -360px #c2c2c2, -499px 473px #e8e8e8, -158px -349px #d4d4d4, 870px -134px #cfcfcf, 446px 404px #c2c2c2,
      440px 490px #d4d4d4, 414px 507px #e6e6e6, -12px 246px #fcfcfc, -384px 369px #e3e3e3, 641px -413px #fcfcfc, 822px 516px #dbdbdb, 449px 132px #c2c2c2, 727px 146px #f7f7f7, -315px -488px #e6e6e6, 952px -70px #e3e3e3,
      -869px -29px #dbdbdb, 502px 80px #dedede, 764px 342px #e0e0e0, -150px -380px #dbdbdb, 654px -426px #e3e3e3, -325px -263px #c2c2c2, 755px -447px #c7c7c7, 729px -177px #c2c2c2, -682px -391px #e6e6e6, 554px -176px #ededed,
      -85px -428px #d9d9d9, 714px 55px #e8e8e8, 359px -285px #cfcfcf, -362px -508px #dedede, 468px -265px #fcfcfc, 74px -500px #c7c7c7, -514px 383px #dbdbdb, 730px -92px #cfcfcf, -112px 287px #c9c9c9, -853px 79px #d6d6d6,
      828px 475px #d6d6d6, -681px 13px #fafafa, -176px 209px #f0f0f0, 758px 457px #fafafa, -383px -454px #ededed, 813px 179px #d1d1d1, 608px 98px whitesmoke, -860px -65px #c4c4c4, -572px 272px #f7f7f7, 459px 533px #fcfcfc,
      624px -481px #e6e6e6, 790px 477px #dedede, 731px -403px #ededed, 70px -534px #cccccc, -23px 510px #cfcfcf, -652px -237px whitesmoke, -690px 367px #d1d1d1, 810px 536px #d1d1d1, 774px 293px #c9c9c9, -362px 97px #c2c2c2,
      563px 47px #dedede, 313px 475px #e0e0e0, 839px -491px #e3e3e3, -217px 377px #d4d4d4, -581px 239px #c2c2c2, -857px 72px #cccccc, -23px 340px #dedede, -837px 246px white, 170px -502px #cfcfcf, 822px -443px #e0e0e0, 795px 497px #e0e0e0,
      -814px -337px #cfcfcf, 206px -339px #f2f2f2, -779px 108px #e6e6e6, 808px 2px #d4d4d4, 665px 41px #d4d4d4, -564px 64px #cccccc, -380px 74px #cfcfcf, -369px -60px #f7f7f7, 47px -495px #e3e3e3, -383px 368px #f7f7f7, 419px 288px #d1d1d1,
      -598px -50px #c2c2c2, -833px 187px #c4c4c4, 378px 325px whitesmoke, -703px 375px #d6d6d6, 392px 520px #d9d9d9, -492px -60px #c4c4c4, 759px 288px #ebebeb, 98px -412px #c4c4c4, -911px -277px #c9c9c9;
  transform-style: preserve-3d;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 4px;
  width: 4px;
  border-radius: 2px;
}
.star-field .layer:nth-child(1) {
  animation: sf-fly-by-1 5s linear infinite;
}
.star-field .layer:nth-child(2) {
  animation: sf-fly-by-2 5s linear infinite;
}
.star-field .layer:nth-child(3) {
  animation: sf-fly-by-3 5s linear infinite;
}
